import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import Plataforma from './Info/Plataforma'
import AppsExternas from './Info/AppsExternas'
import Servidores from './Info/Servidores'
import Redes from './Info/Redes'
import IncidentesRP from './Info/IncidentesRP'
import ContenidoPlataforma from './Info/ContenidoPlataforma'

const DashboardAyudaAdministradores = () => {
  return (
    <Container>
        <Row className='mt-4'>
            <Col>
                <h4>Bienvenido al panel de ayuda para administradores</h4>
                <h5>Aqui encontrarás ayuda para solucionar las fallas o casos más comunes de atender mientras administras la plataforma y servicios</h5>
            </Col>
        </Row>

        <Row className='mt-4'>
            <Col>
                  <Accordion>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>Errores en la plataforma</Accordion.Header>
                          <Accordion.Body>
                              <Plataforma />
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                          <Accordion.Header>Atención a apps externas</Accordion.Header>
                          <Accordion.Body>
                                <AppsExternas />
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                          <Accordion.Header>Servidores</Accordion.Header>
                          <Accordion.Body>
                              <Servidores />
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                          <Accordion.Header>Redes</Accordion.Header>
                          <Accordion.Body>
                              <Redes />
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                          <Accordion.Header>Incidentes en RP</Accordion.Header>
                          <Accordion.Body>
                              <IncidentesRP />
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                          <Accordion.Header>Contenido de la plataforma</Accordion.Header>
                          <Accordion.Body>
                              <ContenidoPlataforma />
                          </Accordion.Body>
                      </Accordion.Item>
                  </Accordion>
            </Col>
        </Row>
    </Container>
  )
}

export default DashboardAyudaAdministradores